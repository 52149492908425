exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-blog-post-js-content-file-path-data-blog-aptos-interpret-medical-interpretability-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/data/blog/aptos-interpret/medical-interpretability.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-data-blog-aptos-interpret-medical-interpretability-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-data-blog-getting-into-ml-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/data/blog/getting-into-ml.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-data-blog-getting-into-ml-md" */),
  "component---src-templates-blog-post-js-content-file-path-data-blog-interpretability-interp-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/data/blog/interpretability/interp.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-data-blog-interpretability-interp-md" */),
  "component---src-templates-blog-post-js-content-file-path-data-blog-llama-3-l-la-ma-3-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/data/blog/llama3/LLaMA3.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-data-blog-llama-3-l-la-ma-3-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-data-blog-mobilenet-to-effnet-mobilenet-to-efficientnet-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/data/blog/mobilenet-to-effnet/mobilenet-to-efficientnet.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-data-blog-mobilenet-to-effnet-mobilenet-to-efficientnet-md" */),
  "component---src-templates-blog-post-js-content-file-path-data-blog-model-scaling-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/data/blog/model-scaling.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-data-blog-model-scaling-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-data-blog-replace-batch-norm-replacing-bn-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/data/blog/replace-batch-norm/replacing-bn.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-data-blog-replace-batch-norm-replacing-bn-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-data-blog-rlhf-min-rlhf-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/data/blog/rlhf/min-rlhf.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-data-blog-rlhf-min-rlhf-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-data-blog-slicer-slicer-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/data/blog/slicer/slicer.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-data-blog-slicer-slicer-md" */),
  "component---src-templates-blog-post-js-content-file-path-data-blog-space-probes-probes-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/data/blog/space-probes/probes.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-data-blog-space-probes-probes-mdx" */)
}

